/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, keywords, title, image }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
						siteUrl
					}
				}
			}
		`
	);

	const metaDescription = description || site.siteMetadata.description;
	image = image ? `${site.siteMetadata.siteUrl}${image}` : `${site.siteMetadata.siteUrl}/logo.png`;
	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={`%s | ${site.siteMetadata.title}`}
			defaultTitle={`${site.siteMetadata.title} — ${site.siteMetadata.description}`}
			meta={[
				{
					name: `viewport`,
					content: 'width=device-width, initial-scale=1.0, maximum-scale=5',
				},
				{
					name: 'theme-color',
					content: '#5F5F5F',
				},
				{
					name: `google-site-verification`,
					content: 'uPQp2trsutpzDx76NJAS-6nQlNouquBlgcDMFwVPxZk',
				},
				{
					name: `Content-Language`,
					content: 'de',
				},
				{
					name: `revisit-after`,
					content: '1 day',
				},
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: title
						? `${title} | ${site.siteMetadata.title}`
						: `${site.siteMetadata.title} — ${site.siteMetadata.description}`,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:site`,
					content: site.siteMetadata.author,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.author,
				},
				{
					name: `twitter:title`,
					content: title
						? `${title} | ${site.siteMetadata.title}`
						: `${site.siteMetadata.title} — ${site.siteMetadata.description}`,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
			]
				.concat(
					keywords.length > 0
						? {
								name: `keywords`,
								content: keywords.join(`, `),
						  }
						: []
				)
				.concat(
					image
						? [
								{
									name: `og:image`,
									content: image,
								},
								{
									name: `twitter:image`,
									content: image,
								},
								{
									name: `twitter:image:alt`,
									content: title
										? `${title} | ${site.siteMetadata.title}`
										: `${site.siteMetadata.title} — ${site.siteMetadata.description}`,
								},
						  ]
						: []
				)
				.concat(meta)}
		/>
	);
}

SEO.defaultProps = {
	lang: `de`,
	meta: [],
	keywords: [],
	description: ``,
	image: ``,
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	keywords: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string,
	image: PropTypes.string,
};

export default SEO;
