import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const Footer = ({ siteTitle }) => (
	<footer id="footer">
		&copy; {new Date().getFullYear()}{' '}
		<span itemProp="author" itemScope itemType="http://schema.org/Person">
			<span itemProp="name">Eduard Baun</span>
		</span>{' '}
		| <Link to="/archiv/">Archiv</Link> | <Link to="/datenschutz.html">Datenschutz</Link> |
		<Link to="/impressum.html">Impressum</Link>
	</footer>
);

Footer.propTypes = {
	siteTitle: PropTypes.string,
};

Footer.defaultProps = {
	siteTitle: ``,
};

export default Footer;
