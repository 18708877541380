import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Logo from '../images/logo.svg';

const Header = ({ siteTitle }) => (
	<header>
		<h1 id="logo">
			<Link to="/">
				<img src={Logo} alt={siteTitle} />
			</Link>
			<span className="title">{siteTitle}</span>
		</h1>
	</header>
);

Header.propTypes = {
	siteTitle: PropTypes.string,
};

Header.defaultProps = {
	siteTitle: ``,
};

export default Header;
